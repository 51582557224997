import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    items: Array
  }

  static targets = [
    "displayedItem", "ejerNavn", "brugerNavn", "chauffoerNavn", "regNr", "modelTekst", "kilometerTal",
    "foerstRegDato", "sidsteRegDato", "brugerNr", "datoSidsteFaktura", "serviceKontrakt", "serviceKontraktUdloeb",
    "kreditStop", "ejerKontakt", "adresse", "postNrBy", "telefonNr", "telefonNr2", "mobilNr", "mobilNr2", "email",
    "email2", "brugerAdresse", "brugerPostNrBy", "brugerTelefonNr", "brugerTelefonNr2", "brugerMobilNr",
    "brugerMobilNr2", "brugerEmail", "chauffoerAdresse", "chauffoerPostNrBy", "chauffoerTelefonNr",
    "chauffoerTelefonNr2", "chauffoerMobilNr", "chauffoerMobilNr2", "chauffoerEmail", "cdkLookup"
  ];
  
  connect() {
    this.updateFields();
  }

  updateFields() {
    let item = this.itemsValue[parseInt(this.displayedItemTarget.value)]
    this.ejerNavnTarget.textContent = item["EjerNavn"];
    this.brugerNavnTarget.textContent = item["BrugerNavn"];
    this.chauffoerNavnTarget.textContent = item["ChaufførNavn"];
    this.regNrTarget.textContent = item["RegNr"];
    this.modelTekstTarget.textContent = item["ModelTekst"];
    this.kilometerTalTarget.textContent = item["KilometerTal"];
    this.foerstRegDatoTarget.textContent = item["FørstRegDato"];
    this.sidsteRegDatoTarget.textContent = item["SidsteRegDato"];
    this.brugerNrTarget.textContent = item["BrugerNr"];
    this.datoSidsteFakturaTarget.textContent = item["DatoSidsteFaktura"];
    this.serviceKontraktTarget.textContent = item["ServiceKontrakt"];
    this.serviceKontraktUdloebTarget.textContent = item["ServiceKontraktUdløb"];
    this.kreditStopTarget.textContent = item["KreditStop"];
    this.ejerKontaktTarget.textContent = item["EjerKontakt"];
    this.adresseTarget.textContent = item["Adresse"];
    this.postNrByTarget.textContent = [item["PostNr"], item["By"]].join(" ");
    this.telefonNrTarget.textContent = item["TelefonNr"];
    this.telefonNr2Target.textContent = item["TelefonNr2"];
    this.mobilNrTarget.textContent = item["MobilNr"];
    this.mobilNr2Target.textContent = item["MobilNr2"];
    this.emailTarget.textContent = item["E-mail"];
    this.email2Target.textContent = item["E-mail2"];
    this.brugerAdresseTarget.textContent = item["BrugerAdresse"];
    this.brugerPostNrByTarget.textContent = [item["BrugerPostNr"], item["BrugerBy"]].join(" ");
    this.brugerTelefonNrTarget.textContent = item["BrugerTelefonNr"];
    this.brugerTelefonNr2Target.textContent = item["BrugerTelefonNr2"];
    this.brugerMobilNrTarget.textContent = item["BrugerMobilNr"];
    this.brugerMobilNr2Target.textContent = item["BrugerMobilNr2"];
    this.brugerEmailTarget.textContent = item["BrugerE-mail"];
    this.chauffoerAdresseTarget.textContent = item["ChaufførAdresse"];
    this.chauffoerPostNrByTarget.textContent = [item["ChaufførPostNr"], item["ChaufførBy"]].join(" ");
    this.chauffoerTelefonNrTarget.textContent = item["ChaufførTelefonNr"];
    this.chauffoerTelefonNr2Target.textContent = item["ChaufførTelefonNr2"];
    this.chauffoerMobilNrTarget.textContent = item["ChaufførMobilNr"];
    this.chauffoerMobilNr2Target.textContent = item["ChaufførMobilNr2"];
    this.chauffoerEmailTarget.textContent = item["ChaufførEmail"];
    this.cdkLookupTarget.href = "https://www.nummerplade.net/nummerplade/" + item["RegNr"] + ".html"
  }
}
